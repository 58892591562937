import { useEffect, useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import {
  TextField,
  MenuItem,
  Button,
  Box,
  Typography,
  Divider,
  FormControl,
  InputLabel,
  Select,
  List,
  ListItem,
  Grid,
  Tab,
  Tabs,
  InputAdornment,
  IconButton,
  ListItemText,
  ListItemIcon,
  Avatar,
  LinearProgress,
} from "@mui/material";
import { ReactComponent as ClearIcon } from "../../assets/icons/clear-search.svg";
import { CheckCircle, PanoramaFishEye } from "@material-ui/icons";
import { useLocation, useHistory } from "react-router-dom";
import api from "../../helpers/ApiClient";
let generateReceiptURL = "v2/receipts/generator";
const mockData = [
  [
    {
      "active": 1,
      "attributes": {
        "elp_promo_id": 0,
        "sub_description": null,
        "channel": "BAYAD",
        "description": "P5000 Load valid for 1 year.",
        "weight": "0",
        "is_promo_load": "1",
        "more_icon": "http://drupalcluster/",
        "promo_icon": "http://drupalcluster/",
        "telco": "SMART",
        "price": 5000,
        "tile_text": null,
        "name": "P5000",
        "elp_keyword": "WGPPL5000",
        "validity": null,
        "brand": "Smart Prepaid"
      },
      "id": 34,
      "type": "load_denom"
    },
    {
      "active": 1,
      "attributes": {
        "elp_promo_id": 0,
        "sub_description": null,
        "channel": "BAYAD",
        "description": "P3000 Load valid for 1 year.",
        "weight": "0",
        "is_promo_load": "1",
        "more_icon": "http://drupalcluster/",
        "promo_icon": "http://drupalcluster/",
        "telco": "SMART",
        "price": 3000,
        "tile_text": null,
        "name": "P3000",
        "elp_keyword": "WGPPL3000",
        "validity": null,
        "brand": "Smart Prepaid"
      },
      "id": 35,
      "type": "load_denom"
    },
    {
      "active": 1,
      "attributes": {
        "elp_promo_id": 0,
        "sub_description": null,
        "channel": "BAYAD",
        "description": "P1000 Load valid for 1 year.",
        "weight": "0",
        "is_promo_load": "1",
        "more_icon": "http://drupalcluster/",
        "promo_icon": "http://drupalcluster/",
        "telco": "SMART",
        "price": 1000,
        "tile_text": null,
        "name": "P1000",
        "elp_keyword": "WGPPL1000",
        "validity": null,
        "brand": "Smart Prepaid"
      },
      "id": 36,
      "type": "load_denom"
    },
    {
      "active": 1,
      "attributes": {
        "elp_promo_id": 0,
        "sub_description": null,
        "channel": "BAYAD",
        "description": "P500 Load valid for 1 year.",
        "weight": "0",
        "is_promo_load": "1",
        "more_icon": "http://drupalcluster/",
        "promo_icon": "http://drupalcluster/",
        "telco": "SMART",
        "price": 500,
        "tile_text": null,
        "name": "P500",
        "elp_keyword": "WGPPL555",
        "validity": null,
        "brand": "Smart Prepaid"
      },
      "id": 37,
      "type": "load_denom"
    },
    {
      "active": 1,
      "attributes": {
        "elp_promo_id": 0,
        "sub_description": null,
        "channel": "BAYAD",
        "description": "P300 Load valid for 1 year.",
        "weight": "0",
        "is_promo_load": "1",
        "more_icon": "http://drupalcluster/",
        "promo_icon": "http://drupalcluster/",
        "telco": "SMART",
        "price": 300,
        "tile_text": null,
        "name": "P300",
        "elp_keyword": "WGPPL333",
        "validity": null,
        "brand": "Smart Prepaid"
      },
      "id": 38,
      "type": "load_denom"
    },
    {
      "active": 1,
      "attributes": {
        "elp_promo_id": 0,
        "sub_description": null,
        "channel": "BAYAD",
        "description": "P200 Load valid for 1 year.",
        "weight": "0",
        "is_promo_load": "1",
        "more_icon": "http://drupalcluster/",
        "promo_icon": "http://drupalcluster/",
        "telco": "SMART",
        "price": 200,
        "tile_text": null,
        "name": "P200",
        "elp_keyword": "WGPPL222",
        "validity": null,
        "brand": "Smart Prepaid"
      },
      "id": 39,
      "type": "load_denom"
    },
    {
      "active": 1,
      "attributes": {
        "elp_promo_id": 0,
        "sub_description": null,
        "channel": "BAYAD",
        "description": "P100 Load valid for 1 year.",
        "weight": "0",
        "is_promo_load": null,
        "more_icon": "http://drupalcluster/",
        "promo_icon": "http://drupalcluster/",
        "telco": "SMART",
        "price": 100,
        "tile_text": null,
        "name": "P100",
        "elp_keyword": "W100",
        "validity": null,
        "brand": "Smart Prepaid"
      },
      "id": 42,
      "type": "load_denom"
    },
    {
      "active": 1,
      "attributes": {
        "elp_promo_id": 0,
        "sub_description": null,
        "channel": "BAYAD",
        "description": "PROMO LOAD P50",
        "weight": "1",
        "is_promo_load": null,
        "more_icon": "http://drupalcluster/",
        "promo_icon": "http://drupalcluster/",
        "telco": "SMART",
        "price": 50,
        "tile_text": null,
        "name": "P50",
        "elp_keyword": "W50",
        "validity": null,
        "brand": "Smart Prepaid"
      },
      "id": 87,
      "type": "load_denom"
    }
  ]
]

const styles = {
  tab: {
    width: "50%",
    minHeight: "50px",
    height: "50px",
    font: "normal normal normal 14px/16px Poppins-Medium !important",
    textTransform: "none", // Disable uppercase text
    "&.Mui-selected": {
      backgroundColor: "#0176C0", // Active tab background color (blue)
      color: "#FFFFFF", // Active tab text color (white)
    },
    backgroundColor: "#D9EAF5", // Default (inactive) background color (white)
    color: "#0176C0", // Default (inactive) text color (blue)
    border: "0px solid #C6E0F1", // Border for both active and inactive tabs
    borderRadius: "8px 8px 0 0", // Optional: Rounded corners for tabs
  },
  textField: {
    font: "normal normal normal 17px/20px Poppins-Regular !important",
    borderRadius: "5px",
    padding: "10px",
    backgroundColor: "#FFFFFF",
  },
  label: {
    font: "normal normal normal 15px/18px Poppins-Regular !important",
  },
   header: {
    font: "normal normal normal 27px/25px Poppins-Regular !important",
    padding:"10px 20px 15px 10px"
   },
  adornment: {
    font: "normal normal normal 17px/20px Poppins-Regular !important",
  },
  submitButton:{
    backgroundColor: "#F26122", // Blue color
    color: "#FFFFFF", // White text color
    textTransform: "none", // Optional: Disable uppercase transformation
    padding:"10px 25px 10px 25px",
    font: "normal normal normal 17px/20px Poppins-Regular !important",
    "&:hover": {
      backgroundColor: "#D44F1D", // Darker blue on hover
    },
    "&.Mui-disabled": {
      backgroundColor: "#cccccc", // Grey color when disabled
      color: "#8B9095", // Text color when disabled
    },
  },
  searchField: {
    padding: "10px",
    height: "40px",
    backgroundColor: "#F5F5F5",
    borderRadius: "5px",
    font: "normal normal normal 14px/16px Poppins-Regular",
    "& .MuiInput-underline:after": {
      borderBottomColor: "orange",
    },
    underlineColor: {
      "& .MuiInput-underline:after": {
        borderBottomColor: "#0176C0",
      },
    },
  },
  loading: {
    height: "2px",
    width: "100%",
    overflow: "hidden",
    backgroundColor: "#AAD1EA",
    "& .MuiLinearProgress-bar": {
      backgroundColor: `#0176C0`,
    },
  },
  list: {
    flex: 1,
    overflow: "hidden",
    maxHeight: "605px", // Fixed height for the box to make it scrollable
    overflowY: "auto", // Enable vertical scrolling
    overflowX: "hidden", // Prevent horizontal scrolling
    backgroundColor: "#fff",
    borderBottom: "solid 2px #0176C0", // Bottom border for active tab
    borderLeft: "solid 2px #0176C0", // Left border for active tab
    borderRight: "solid 2px #0176C0",
    borderRadius:"0px 0px 15px 15px",
    padding:"1px",// Right border for active tab
    clipPath: "inset(-10px 0 0 0)", 
    "&::-webkit-scrollbar": {
  width: "7px", // Width of the scrollbar
},
"&::-webkit-scrollbar-track": {
  background: "#f1f1f1", // Background of the scrollbar track
},
"&::-webkit-scrollbar-thumb": {
  background: "rgba(193, 193, 193, 0.3)", // Less opacity when not hovered
  borderRadius: "10px", // Round edges of the thumb
},
"&::-webkit-scrollbar-thumb:hover": {
  background: "#a1a1a1", // Color of the thumb on hover
},
  }
  //----------------------------------------------------------------------------------------------------------------
  // categoryWrapper: {
  //   marginBlock: "5px",
  //   display: "grid",
  //   gridTemplateColumns: "40%  min-content",
  //   gap: "5px",
  // },
  // categoryLabel: {
  //   color: "black",
  //   margin: "auto",
  // },
  // alertSucess: {
  //   background: "green",
  //   color: "white",
  //   borderRadius: "10px",
  //   paddingTop: "7px",
  //   paddingBottom: "7px",
  //   paddingRight: "7px",
  //   width: "calc(100% - 30px)",
  // },
  // alertError: {
  //   background: "#D32F2F",
  //   color: "white",
  //   borderRadius: "10px",
  //   paddingTop: "7px",
  //   paddingBottom: "7px",
  //   paddingRight: "7px",
  //   width: "calc(100% - 30px)",
  // },
};

const BuyLoad = () => {
  const searchRef = useRef();
  const [formData, setFormData] = useState({
    mobileNo: "",
    telco: "Smart",
  });

  const [filter, setFilter] = useState({
    search: "",
    type: "load",
  });

  const [errors, setErrors] = useState({
    mobileNo: false,
  });

  const history = useHistory();


  const isFormValid = () => {
    return (
      formData.mobileNo.trim().length === 10// Mobile number should have 10 digits
    
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    // Validate on change
    if (name === "mobileNo") {
      setErrors((prev) => ({
        ...prev,
        mobileNo: value.trim().length !== 10, // Error if mobile number is not 10 digits
      }));
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;

    // Set errors based on field validation
    if (name === "mobileNo") {
      setErrors((prev) => ({
        ...prev,
        mobileNo: value.trim().length !== 10, // Error if not 10 digits
      }));
    } 
  };


  const handleTabChange = (event, newValue) => {
    console.log("Selected Tab:", newValue); // Log the selected tab value
    setFilter((prev) => ({
      ...prev,
      type: newValue, // Update the filter state with the new tab value
    }));
  };
  const [selectedItemId, setSelectedItemId] = useState(null); // Tracks the selected item

  const handleSelect = (itemId) => {
    setSelectedItemId(itemId === selectedItemId ? null : itemId); // Toggle selection
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isFormValid()) {
      console.log("Form is invalid");
      return;
    }
    const selectedItem = mockData.flat().find((item) => item.id === selectedItemId);
    const selectedItemPrice = selectedItem?.attributes?.price;
    console.log("Form Submitted:", formData);
     const payload = {
			referenceNumber: formData?.mobileNo,
			amount: selectedItemPrice,
		};
    
    const response = api.post(generateReceiptURL,payload)
    console.log("resp>>", response);
    // Add submission logic here
  };

  const onClear = () => {
		searchRef.current.value = "";
    handleInputChange({name:"search",value:""})
		// filterChange();
	};


  return (
    <Box
      sx={{
        maxWidth: 870,
        minHeight: 820,
        margin: "40px auto",
        backgroundColor: "white",
        borderRadius: 2,
        boxShadow: 3,
        padding: 3,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* {Title Section} */}
      <Typography variant="h5" gutterBottom align="left" sx={styles.header}>
        Mobile Load
      </Typography>

      <Box display={"flex"} sx={{ height: "710px",gap:"2rem" }}>
        {/* {Form Section} */}
        <Box sx={{ width: "40%" }}>
        <form onSubmit={handleSubmit}>
            {/* Mobile Number Field */}
            <TextField
              fullWidth
              label="Enter Customer Mobile Number"
              name="mobileNo"
              placeholder="xxx xxx xxxx"
              value={formData.mobileNo}
              onChange={handleInputChange}
              onBlur={handleBlur}
              margin="normal"
              size="small"
              type="tel"
              required
              error={errors.mobileNo}
              helperText={errors.mobileNo ? "Mobile number must be 10 digits" : ""}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    sx={{
                      "& .MuiTypography-root": {
                        ...styles.adornment, // Ensure styles.label.font is applied
                      },
                      marginRight: "0px", //
                    }}
                    position="start"
                  >
                    +63
                  </InputAdornment>
                ),
              }}
              inputProps={{
                maxLength: 10, // Limit input to 10 digits (for mobile numbers)
              }}
              sx={{
                "& .MuiInputBase-input": {
                  font: styles.textField.font,
                  borderRadius: styles.textField.borderRadius,
                  padding: styles.textField.padding,
                  backgroundColor: styles.textField.backgroundColor,
                },
                "& .MuiInputLabel-root": {
                  font: styles.label.font,
                },
              }}
            />

            {/* Telco Dropdown */}
            <FormControl
              variant="outlined"
              style={{
                minWidth: "100%",
                top: "3px",
              }}
            >
              <InputLabel
                className="category_label"
                htmlFor="outlined-age-native-simple"
              >
                Select Telco
              </InputLabel>
              <Select
                fullWidth
                className="category_items"
                native
                value={formData.telco}
                sx={{ height: "42px", width: "100%" }}
                onChange={(e) => {
                  const { value } = e.target;
                  setFormData((prev) => ({ ...prev, telco: value })); // Update formData state
                }}
                label={"Select Telco"}
                inputProps={{
                  name: "telco",
                  id: "outlined-age-native-simple",
                }}
              >
                <option value={"Smart"} key={"Smart"}>
                  Smart
                </option>
                <option value={"Globe"} key={"Globe"}>
                  Globe
                </option>
                <option value={"Dito"} key={"Dito"}>
                  Dito
                </option>
              </Select>
            </FormControl>

            
          </form>
        </Box>
        {/* List Section */}
        <Box sx={{ width: "60%", borderRadius:"10px",height: "100%"}}>
          <Tabs
            sx={{ maxHeight: "0px" , "& .MuiTabs-indicator": {
            backgroundColor: "#0176C0", // Set underline color to blue
            height: "0px", // Optional: Adjust thickness
          },borderBottom:"solid 2px  #0176C0"}}
            name="type"
            disableRipple
            value={filter.type}
            onChange={handleTabChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            <Tab sx={styles.tab} disableRipple value="load" label="Load" />
            <Tab sx={styles.tab} disableRipple value="promo" label="Promo" />
          </Tabs>
          {/* <LinearProgress
            sx={{
              ...styles.loading,
            }}
          /> */}
          <TextField
							fullWidth
							size="small"
							className="search"
							placeholder="Search other product.."
							variant="standard"
							inputRef={searchRef}
							// onChange={debounceOnChange}
							sx={{
								...styles.searchField.underlineColor,borderRight:"solid 2px #0176C0",borderLeft:"solid 2px #0176C0"
							}}
							InputProps={{
								style: {
									...styles.searchField,
								},
								startAdornment: (
									<InputAdornment
										style={{ paddingRight: "4px" }}
									>
										<SearchIcon />
									</InputAdornment>
								),
								endAdornment: (
									<InputAdornment
										position="end"
										onClick={() => {
											onClear();
										}}
									>
										<ClearIcon
											style={{
												display: `${
													filter.search
														? "flex"
														: "none"
												}`,
												cursor: "pointer",
												height: "20px",
												width: "20px",
											}}
										/>
									</InputAdornment>
								),
							}}
						/>
            {/* list */}
            <Box
      sx={styles.list}
    >
      <List>
        {mockData.flat().map((item) => (
          <ListItem
            key={item.id}
            onClick={() => handleSelect(item.id)}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px 16px",
              borderRadius:"10px",
              marginX:"5px",
              marginY:"5px",
              border: selectedItemId === item.id ? "solid 2px #F6956B" : "none",
              "&:hover": {
                cursor:"pointer",
                backgroundColor: "#f9f9f9",
              },
            }}
          >
            {/* Middle Section: Name and Details */}
            <ListItemText
              primary={
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: 500,
                  }}
                >
                  {item.attributes.name}
                </Typography>
              }
              secondary={
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "Poppins, sans-serif",
                    color: "#8B9095",
                    fontSize: "12px", // Smaller font for details
                  }}
                >
                  {item.attributes.description}
                </Typography>
              }
            />

            {/* Right Section: Actions */}
            <Box sx={{ display: "flex", alignItems: "center" }}>
            
                    {selectedItemId === item.id ? (
                      <CheckCircle style={{ color: "#F26122" }} /> // Green check icon if selected
                    ) : (
                      <PanoramaFishEye style={{ color: "#B0BEC5" }} /> // Default icon if not selected
                    )}
                  

              
            </Box>
          </ListItem>
        ))}
      </List>
    </Box>
        </Box>
      </Box>

      {/* {Action Section} */}

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end", // Aligns the button to the right
        }}
      >
        <Button
          variant="contained"
          color="inherit"
          size="medium"
          disableElevation
          sx={styles.submitButton}
          onClick={handleSubmit}
          disabled={!isFormValid()}
        >
          Pay
        </Button>
      </Box>
    </Box>
  );
};

export default BuyLoad;